import React, {Component} from 'react';
import logo from './logo.svg';
import './App.scss';
import Input from './Input.js';

import firebase from './fire.js';
const storage = firebase.storage();

//https://en.wikipedia.org/w/api.php?format=json&action=query&prop=extracts&exintro&explaintext&redirects=1&titles=Anna%20Nicole%20Smith

class App extends Component {
  constructor(props){
    super(props);
    this.firebaseRef = firebase.database();

    this.state = {
      isDead:false
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.getInfo = this.getInfo.bind(this);
    this.searchFromList = this.searchFromList.bind(this);
    this.searchForDeath = this.searchForDeath.bind(this);
    this.checkDevils = this.checkDevils.bind(this);
    this.counter = 0;
  }

  componentDidMount() {
    var _projects = [];

    this.firebaseRef.ref('popular').on('value', function(snap){
      _projects = snap.val();
      this.setState({
        popular: snap.val()
      });

    }.bind(this));
  }

  fetchData(url, query){

    this.setState({loading:true})

    fetch(url + query + "?redirect=yes", {
    	"method": "GET",
    	"headers": {
        "origin":"*",
        "Accept-Language": "sv"
    	}
    })
    .then(blob => blob.json())
    .then(data => {
      console.log('data: ', data);

      this.setState({data:data, loading:false})
      return data;
    })
    .catch(e => {
      console.log('e: ',e);
      return e;
    });

  }

  handleInputChange(val){
    console.log('changing', val);
    this.setState({
      query:val,
      searching:false,
      data:{}
    })
  }

  checkDevils(){
    let isDevil = false;
    let devils = ["hitler", "devil", "stalin", "al-Baghdadi", "bin laden", "pol pot", "idi amin", "mao", "charles cullen"];

    let q = this.state.query.toLowerCase();

    for (var i = 0; i < devils.length; i++) {
      isDevil = (q).match( devils[i]) ? true : isDevil;
    }

    return isDevil;
  }

  sortData(items, prop) {
    let sortedItems = Object.keys(items).sort(function(a, b) {

    var nameA = parseInt(items[a][prop]); // ignore upper and lowercase
    var nameB = parseInt(items[b][prop]); // ignore upper and lowercase

    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }

    // names must be equal
    return 0;
    });

    return sortedItems;
  }

  handleSearch(){

    this.setState({
      searching:true
    })

    let query = this.state.query;

    let str = query.split(" ");
    console.log('qstr: ', str);

    for (var i = 0, x = str.length; i < x; i++) {

        str[i] = str[i][0] !== undefined ? str[i][0].toUpperCase() + str[i].substr(1) : false;
        if(!str[i]) delete str[i];
    }

    query = str.join(" ");

    // query.replace(' ', '%20');
    query.replace(' ', '_');

    let url = "https://sv.wikipedia.org/api/rest_v1/page/summary/";
    this.fetchData(url, query);

    // console.log('indexof: ', this.state.popular.find(query))
    // this.firebaseRef.ref('popular').push(query)

    let previousSearches = this.updatePopularityList(this.state.popular, query);

    console.log('isSearched: ' , previousSearches);

  }

  countUp(id){

    let updatedObj = this.state.popular[id];
    updatedObj.count++;
    this.firebaseRef.ref('popular/'+ id ).set(updatedObj)
  }

  addNewSearchToList(str){
    let newObj = {
      count:1,
      name: str
    };

    this.firebaseRef.ref('popular').push(newObj)


  }

  updatePopularityList(objects, str){

    str = str.toLowerCase();
    let isInList = false;

    Object.keys(objects).map((i)=>{
      if( objects[i].name.match(str)){
        isInList = true;
        this.countUp(i);
      }
      else {
      };
    })

    if(!isInList){
      this.addNewSearchToList(str);
    }

  }

  searchForDeath(){
    let data = this.state.data;
    // let isAlive = false;

    if(!data.extract){return false;}

    let firstLine = data.extract.split('.')[0];

    let referred = (firstLine.match("refer") || firstLine.match("betyder")) ? true : false;
    let isAlive = (firstLine.match("was") || firstLine.match("död")) ? false : true;

    if(this.state.isDead === isAlive){
      this.setState({
       isDead: !isAlive,
      });
    }

    if(referred){
      return (
        ""
      )
    }

    let isDevil = this.checkDevils();


    if(!isAlive){

      let smiley = (isDevil) ? "" : ":(";

      return (
        "Nej " + smiley
      )
    }
    else {
      let smiley = (!isDevil) ? ":)" : "";

      return (
        "Ja " + smiley
      )
    }


  }

  searchFromList(e){
    let q = e.target.innerHTML;

    this.handleInputChange(q);

    setTimeout(function () {
      this.handleSearch();

    }.bind(this), 500);

  }

  getInfo(){
    let data = this.state.data;
    let popular = this.state.popular || {};

    if(!data || !this.state.searching){
      if(Object.keys(popular).length>0){

        return (
          <div className="popular">
            <h3>Mest populära sökningar</h3>
            <ul>
            {
              this.sortData(popular,"count").map((key,val)=>{
                console.log('key: ', key);
                if(val <=4 ){
                  return (
                    <li key={"pop-" + key} onClick={(e)=>this.searchFromList(e)} >{popular[key].name}</li>
                  )
                }
                else {
                  return false;
                }
              })
            }
            </ul>
          </div>
        );
      }
      else {return false}
    }

    if(data && Object.keys(data).length === 0){
      return false;
    }

    if(data.extract){
      let answer = this.searchForDeath();

      let img = (data.thumbnail) ? <img src={data.thumbnail.source} /> : "";


      return (
        <div>
          {img}
          <h1 className="answer">{answer}</h1>
          <p>{this.state.data.extract}</p>
        </div>
      )
    }
    else if (this.state.query && this.state.searching || data.title === "Not found"){
      let url = "https://en.wikipedia.org/api/rest_v1/page/related";
      // this.fetchData(url, this.state.query);
      return (
        <p>Hittade tyvärr ingen vid namn <b>{this.state.query}</b>, försök vara mer specifik.</p>
      )
    }
  }

  render(){

    let info = this.getInfo();

    return (
      <div className={ this.state.isDead ? "App dead" : "App"}>
        <h1>Lever hen?</h1>
        <Input value={this.state.query} onChange={this.handleInputChange} onClick={this.handleSearch} />

        <div className={this.state.loading ? "loading info" : "info"}>
          {info}
        </div>
      </div>
    );
  }
}

export default App;

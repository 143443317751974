import React, { Component } from 'react';

import './Button.css';

class Button extends Component {

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  componentDidMount(){

  }

  handleMouseUp(e){
    this.mousedown = false;
    const target = e.target;
    target.classList.remove('mouse-down');
  }

  handleMouseDown(e){
    const target = e.target;
    this.mousedown = true;

    setTimeout(function () {
      if(this.mousedown){
        target.classList.add('mouse-down');
      }

    }.bind(this), 500);
  }

  handleClick(e){
    this.mousedown = false;
    const target = e.target;
    target.classList.remove('mouse-down');
    target.classList.add('clicked');
    if(this.props.onClick){
       this.props.onClick()
     } 

    setTimeout(function () {
      target.classList.remove('clicked');
    }, 500);
  }

  render() {

    return (
      <div className="btn" onClick={this.handleClick} onMouseDown={this.handleMouseDown} onMouseOut={this.handleMouseUp}>{this.props.text}</div>
    );
  }
}

export default Button;

// EditArea.propTypes = {
//   text: React.PropTypes.string.isRequired
// }

import React, { Component } from 'react';

import './Input.css';
import Button from './Button.js';

class Input extends Component {

  constructor(props){
    super(props);
    this.value = this.props.value || "";
    this.type = this.props.type || 'text';
    this.checked = this.props.checked || false;

    if( this.type === "submit"){
      this.value = this.props.label;
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(props, state){
    this.value = (props.value) ? props.value : "";

    if( this.type === "submit"){
      this.value = props.label;
    }
  }
  handleChange(e){
    e.preventDefault();
    console.log('e: ', e);
    const target = e.target;
    var val = target.value;

    this.props.onChange(val);
    return false;
  }

  handleSubmit(e){
    console.log('- - - - - - -');
    console.log('e: ', this.value);
    e.preventDefault();
    this.props.onClick(this.value);

  }

  render() {

    return (
      <form className="input-wrapper" onSubmit={this.handleSubmit}>

        <input
          type={this.type} name={'input-' + (this.props.id)}
          placeholder={this.props.label}
          min="0"
          value={this.value}
          onChange={this.handleChange}
          />
          <input className={(this.props.onClick) ? 'btn--small' : 'hidden'} type="submit" value="Sök" />


      </form>
    );
  }
}

export default Input;

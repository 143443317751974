import firebase from 'firebase';


const config = {
  apiKey: "AIzaSyA4tHASn6nHIEYdSASgavWrBb2ZiGpWzDY",
  authDomain: "lever-hen.firebaseapp.com",
  databaseURL: "https://lever-hen.firebaseio.com",
  projectId: "lever-hen",
  storageBucket: "lever-hen.appspot.com",
  messagingSenderId: "210984177527",
  appId: "1:210984177527:web:65415a0d0291c835309b72",
  measurementId: "G-CB7R6PZSHE"
};
var fire = firebase.initializeApp(config);
firebase.analytics();
export default fire;
